var sliderPlayer = null;
var mainPlayer = null;
var appStoreUrl = 'https://apps.apple.com/id/app/bold-race/id1618914426';
var playStoreUrl =
  'https://play.google.com/store/apps/details?id=com.anantarupastudios.labold';

var tag = document.createElement('script');
tag.src = 'https://www.youtube.com/iframe_api';

var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

function onYouTubeIframeAPIReady() {
  mainPlayer = new YT.Player('main-player', {
    width: '100%',
    height: '100%',
    videoId: 'KZIL6Wihb9c',
    playerVars: {
      rel: 0,
      controls: 0,
    },
  });
}

function onPlayerReady(event) {
  event.target.playVideo();
}

function sliders(Swiper) {
  return {
    swiper: null,
    init(el) {
      this.swiper = new Swiper(el, {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        breakpoints: {
          991: {
            autoHeight: true,
          },
        },
      });
      this.swiper.on('slideChangeTransitionEnd', function (e) {
        var index = e.activeIndex;
        var slide = e.slides[index];
        if (slide.classList.contains('slider__item--video')) {
          // buat iframe
          var iframeWrapper = slide.children[0];
          var iframe = iframeWrapper.appendChild(document.createElement('div'));
          iframe.id += `yt-slider-${index}`;
          // check videoId
          var isMobile = window.matchMedia('(max-width: 767px)').matches;
          var videoId = isMobile ? '0OLqCy5pGIc' : 'HAuIHzoRo7A';
          // init yt iframe
          sliderPlayer = new YT.Player(iframe, {
            width: '100%',
            height: '100%',
            videoId: videoId,
            playerVars: {
              playlist: videoId,
              rel: 0,
              loop: 1,
              controls: 0,
            },
            events: {
              onReady: onPlayerReady,
            },
          });
        } else {
          if (sliderPlayer) {
            sliderPlayer.destroy();
          }
        }
      });
    },
    playVideo(event) {},
    nextSlide() {
      this.swiper.slideNext();
    },
    prevSlide() {
      this.swiper.slidePrev();
    },
    handleDownloadWithOs(os) {
      downloadApp();
      if (os === 'android') {
        setTimeout(() => {
          window.open(playStoreUrl, '_blank');
        }, 500);
      } else if (os === 'apple') {
        setTimeout(() => {
          window.open(appStoreUrl, '_blank');
        }, 500);
      }
    },
    handleDownload() {
      downloadApp();
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        setTimeout(() => {
          window.open(playStoreUrl, '_blank');
        }, 500);
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        setTimeout(() => {
          window.open(appStoreUrl, '_blank');
        }, 500);
      }
    },
  };
}

window.sliders = sliders;

function handleRedirectFromBarcode() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  var url = new URL(window.location.href);
  var path = url.pathname === '/boldar/' || url.pathname === '/boldar';

  if (/android/i.test(userAgent) && path) {
    downloadApp();
    setTimeout(() => {
      window.open(playStoreUrl, '_blank');
    }, 500);
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream && path) {
    downloadApp();
    setTimeout(() => {
      window.open(appStoreUrl, '_blank');
    }, 500);
  }
}

handleRedirectFromBarcode();

function downloadApp() {
  firePEvent(72);
  ga('send', 'event', {
    eventCategory: 'APK',
    eventAction: 'download',
    eventLabel: 'Download Bold AR App',
  });
}

function get(name) {
  if (
    (name = new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)').exec(
      location.search,
    ))
  )
    return decodeURIComponent(name[1]);
}

function logIt(msg) {
  if (typeof console !== 'undefined') {
    console.log(msg);
  }
}

function fireTracker(url) {
  $.ajax({
    url: url,
  });
}

var pokktParam = get('pokkt_idfa');
var appId = get('aid');
var offerId = get('oid');
var bundleName = get('bn');

if (typeof pokktParam !== 'undefined' && pokktParam.trim() !== '') {
  var impTracker =
    'https://us-tracker.pokkt.com/api/VideoEventTracker?birthday=&country=philippines&app_version=&device_model=&categoryIab=&city=manila&app_bundle_name=[BUNDLE_NAME]&latitude=0.0&screen=0&skip=0&language=&device_type=&creative_id=-1&platform=android&device_name=android&eap=2.0&mac_address=&appId=[APP_ID]&exdi=&sdk_version=&adv_id=467&state=national+capital+region&deal_id=&carrier_name=Philippine+Long+Distance+Telephone&brand=Generic&campaign_id=6224&opt_userid=&timestamp=1524814992941&longitude=0.0&device_id=814dc408-3445-4d81-966a-463e5382e257&connection_type=&appName=Hay+Day+Android&ip=119.92.244.146&os_version=&sex=&advertisingID=[IDFA]&partner_param=&vc=0.0&offer_id=[OFFER_ID]&token=2ddca0f3f60656c28a41e0d88f1f9ed6&ad_id=8898&user_id=0&track_id=1af93010-16c2-4ba0-9080-71ff2f91adbc&marketing_name=&channel_id=2394&encodedPubParams=&event=71&r_type=img';

  $(document).ready(function () {
    logIt('adding imp tracker!! ' + pokktParam);
    var impTr = impTracker
      .replace('[IDFA]', pokktParam)
      .replace('[BUNDLE_NAME]', bundleName)
      .replace('[APP_ID]', appId)
      .replace('[OFFER_ID]', offerId);
    logIt('imp tracker!! ' + impTr);
    $('body').append(
      '<img height="1" width="1" src=\'' +
        impTr +
        '\' style="display: none" />',
    );
  });
}

function firePEvent(event_id) {
  console.log('call firePEvent ' + event_id);
  if (typeof pokktParam !== 'undefined' && pokktParam.trim() !== '') {
    var clkTracker =
      'https://us-tracker.pokkt.com/api/VideoEventTracker?birthday=&country=philippines&app_version=&device_model=&categoryIab=&city=manila&app_bundle_name=[BUNDLE_NAME]&latitude=0.0&screen=0&skip=0&language=&device_type=&creative_id=-1&platform=android&device_name=android&eap=2.0&mac_address=&appId=[APP_ID]&exdi=&sdk_version=&adv_id=467&state=national+capital+region&deal_id=&carrier_name=Philippine+Long+Distance+Telephone&brand=Generic&campaign_id=6224&opt_userid=&timestamp=1524814992941&longitude=0.0&device_id=814dc408-3445-4d81-966a-463e5382e257&connection_type=&appName=Hay+Day+Android&ip=119.92.244.146&os_version=&sex=&advertisingID=[IDFA]&partner_param=&vc=0.0&offer_id=[OFFER_ID]&token=2ddca0f3f60656c28a41e0d88f1f9ed6&ad_id=8898&user_id=0&track_id=1af93010-16c2-4ba0-9080-71ff2f91adbc&marketing_name=&channel_id=2394&encodedPubParams=&event=[EVENT]';

    logIt('click on button!! ' + pokktParam + ' event ' + event_id);
    var clkTr = clkTracker
      .replace('[IDFA]', pokktParam)
      .replace('[BUNDLE_NAME]', bundleName)
      .replace('[APP_ID]', appId)
      .replace('[OFFER_ID]', offerId)
      .replace('[EVENT]', event_id);
    logIt('clkTracker!! ' + clkTr);
    fireTracker(clkTr);
  }
}
